<script>
import axios from 'axios';
import {
  required,
  
} from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import Swal from 'sweetalert2';
var _ = require('lodash');
/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    
  },
  page: {
    title: "Form Validation",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Form Registrasi Pelanggan Baru",
      items: [
        {
          text: "Forms",
          href: "/",
        },
        {
          text: "Validation",
          active: true,
        },
      ],
     
      tooltipform: {
        fname: "",
        lname: "",
        username: "",
        city: "",
        state: "",
      },
      identitasPelangganForm: {
        fname: "",
        lname: "",
        email: "",
        nomorHandphone: "",
        nomorHandphoneAlternatif: "",
        nomorKtpTandaPengenal: "",
      },
      nonNormalInputNumber:{
        nomorHandphone: "",
        nomorHandphoneAlternatif: "",
      },
      alamatPelanggan: {
        negara: [],
        provinsi: [],
        kota: [],
        kecamatan: [],
        desa: [],
        alamatLengkap: "",
      },
     
      informasiTambahan: {
        clientGrup: "",
        status: "pending",
        catatanTambahan: "",

      },
      listClientGrup:[],
      defaultStatus:"Pending",

      alamatData: {
        NegaraTerpilih: '',
        ProvinsiTerpilih: '',
        kecamatanTerpilih:'',
        kotaTerpilih: '',
        desaTerpilih: '',
        alamatLengkap: " ",

      },
      statusPelanggan: [
        { status: 'Pending' },
        { status: 'Active' },
        { status: 'Suspend' },
        { status: 'Terminate' },
        { status: 'Cancel' },
        { status: 'Inactive' },
        { status: 'uncover' },
      ],
      valueProgressBar: 0,
      inputStyle:{
        focused1 : "",
        focused2 : "",
        focused3 : "",
        focused4: "",
        focused5: "",
        focused6: "",
        focused7: "",

      },
      activityDataSetRegis: {
      user          : "",
      message       : "Melakukan Registrasi",
      refRoute      : "",
      activityType  : "Register",
      handleBy      : "",
    },
      

      progressBarValidator:{
        fname:false,
        lname:false,
        email: false,
        nomorHandphone: false,
        nomorHandphoneAlternatif: false,
        nomorKtpTandaPengenal:false ,
        NegaraTerpilih: false,
        ProvinsiTerpilih: false,
        kecamatanTerpilih: false,
        kotaTerpilih: false,
        desaTerpilih: false,
        alamatLengkap:  false,
        clientGrup: false,
        status: false,
        catatanTambahan:false ,

      },

     

      typeform: {
        name: "",
        password: "",
        confirmPassword: "",
        email: "",
        url: "",
        digit: "",
        number: "",
        alphanum: "",
        textarea: "",
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      emailBlured : false,
      valid : false, 
      maxEmailMin :0,
      axioscheck : false,
      phoneNumberChecker:false,
      phoneNumberAltChecker:false,
    };
  },
  validations: {
    form: {
      fname: {
        required,
      },
      lname: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
      zipcode: {
        required,
      },
    },
    identitasPelangganForm: {
      fname: {
        required,
      },
      lname: {
        required,
      },
      email: {
        required,
      },
      city: {
        required,
      },
      nomorHandphone: {
        required,
      },
      nomorHandphoneAlternatif: {
        required,
      },
      nomorKtpTandaPengenal: {
        required,
      },
    },

    alamatData:{
      NegaraTerpilih:  {
        required,
      },
      ProvinsiTerpilih:  {
        required,
      },
      kotaTerpilih:  {
        required,
      },
      kecamatanTerpilih:  {
        required,
      },

      desaTerpilih:  {
        required,
      },
      alamatLengkap:  {
        required,
      },

    },
    

    informasiTambahan:{
      clientGrup:  {
        required,
      },
      status:  {
        required,
      },
     
    },

    tooltipform: {
      fname: {
        required,
      },
      lname: {
        required,
      },
      username: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
    },

   
    
 
   
    
  },
  mounted(){
    if(!_.isEmpty(this.$route.query)){
      this.nonNormalInputNumber.nomorHandphone = this.$route.query.phoneNumber
      this.identitasPelangganForm.fname = this.$route.query.name
      this.alamatData.alamatLengkap = this.$route.query.address
    }
  },
  watch: {
    'listNegara': {
      immediate: true,
      async handler() {
        await this.choseProvinsi()

      },
    },

    'identitasPelangganForm': {
      
      async handler() {
        
        if (this.progressBarValidator.fname == false && this.valueProgressBar >= 0 && this.identitasPelangganForm.fname !=''){
          this.valueProgressBar = this.valueProgressBar + 7
          this.progressBarValidator.fname = true
          
        }
        else if (this.progressBarValidator.fname == true && this.valueProgressBar >= 0 && this.identitasPelangganForm.fname ==''){
          this.valueProgressBar = this.valueProgressBar - 7
          this.progressBarValidator.fname = false
          
        }

        if (this.progressBarValidator.lname == false && this.valueProgressBar >= 0 && this.identitasPelangganForm.lname !=''){
          this.valueProgressBar = this.valueProgressBar + 7
          this.progressBarValidator.lname = true
          
        }
        else if (this.progressBarValidator.lname == true && this.valueProgressBar >= 0 && this.identitasPelangganForm.lname ==''){
          this.valueProgressBar = this.valueProgressBar - 7
          this.progressBarValidator.lname = false
         
        }


      
        
       

        if (this.progressBarValidator.nomorHandphone  == false && this.valueProgressBar >= 0 && this.identitasPelangganForm.nomorHandphone.length >4 && this.phoneNumberMethod(this.nonNormalInputNumber.nomorHandphone)){
          this.valueProgressBar = this.valueProgressBar + 7
          this.progressBarValidator.nomorHandphone = true
                }
        else if (this.progressBarValidator.nomorHandphone == true && this.valueProgressBar >= 0 && this.identitasPelangganForm.nomorHandphone =='' && this.phoneNumberMethod(this.nonNormalInputNumber.nomorHandphone)){
          this.valueProgressBar = this.valueProgressBar - 7
          this.progressBarValidator.nomorHandphone = false
          
        }

        

        if (this.progressBarValidator.nomorKtpTandaPengenal  == false && this.valueProgressBar >= 0 && this.identitasPelangganForm.nomorKtpTandaPengenal !=''){
          this.valueProgressBar = this.valueProgressBar + 8
          this.progressBarValidator.nomorKtpTandaPengenal = true
         
        }
        else if (this.progressBarValidator.nomorKtpTandaPengenal == true && this.valueProgressBar >= 0 && this.identitasPelangganForm.nomorKtpTandaPengenal ==''){
          this.valueProgressBar = this.valueProgressBar - 8
          this.progressBarValidator.nomorKtpTandaPengenal = false
          
        }



      },
      deep: true
    },

    'alamatData': {
      
      async handler() {
        
      
        if (this.progressBarValidator.NegaraTerpilih == false && this.valueProgressBar >= 0 && this.alamatData.NegaraTerpilih !=''){
          this.valueProgressBar = this.valueProgressBar + 7
          this.progressBarValidator.NegaraTerpilih = true
          
        }
        else if (this.progressBarValidator.NegaraTerpilih == true && this.valueProgressBar >= 0 && this.alamatData.NegaraTerpilih ==''){
          this.valueProgressBar = this.valueProgressBar - 7
          this.progressBarValidator.NegaraTerpilih = false
         
        }


        if (this.progressBarValidator.ProvinsiTerpilih == false && this.valueProgressBar >= 0 && this.alamatData.ProvinsiTerpilih !=''){
          this.valueProgressBar = this.valueProgressBar + 7
          this.progressBarValidator.ProvinsiTerpilih = true
         
        }
        else if (this.progressBarValidator.ProvinsiTerpilih == true && this.valueProgressBar >= 0 && this.alamatData.ProvinsiTerpilih ==''){
          this.valueProgressBar = this.valueProgressBar - 7
          this.progressBarValidator.ProvinsiTerpilih = false
         
        }


        if (this.progressBarValidator.kecamatanTerpilih == false && this.valueProgressBar >= 0 && this.alamatData.kecamatanTerpilih !=''){
          this.valueProgressBar = this.valueProgressBar + 7
          this.progressBarValidator.kecamatanTerpilih = true
         
        }
        else if (this.progressBarValidator.kecamatanTerpilih == true && this.valueProgressBar >= 0 && this.alamatData.kecamatanTerpilih ==''){
          this.valueProgressBar = this.valueProgressBar - 7
          this.progressBarValidator.kecamatanTerpilih = false
         
        }


        if (this.progressBarValidator.kotaTerpilih == false && this.valueProgressBar >= 0 && this.alamatData.kotaTerpilih !=''){
          this.valueProgressBar = this.valueProgressBar + 16
          this.progressBarValidator.kotaTerpilih = true
         
        }
        else if (this.progressBarValidator.kotaTerpilih == true && this.valueProgressBar >= 0 && this.alamatData.kotaTerpilih ==''){
          this.valueProgressBar = this.valueProgressBar - 16
          this.progressBarValidator.kotaTerpilih = false
         
        }


        if (this.progressBarValidator.desaTerpilih == false && this.valueProgressBar >= 0 && this.alamatData.desaTerpilih !=''){
          this.valueProgressBar = this.valueProgressBar + 16
          this.progressBarValidator.desaTerpilih = true
         
        }
        else if (this.progressBarValidator.desaTerpilih == true && this.valueProgressBar >= 0 && this.alamatData.desaTerpilih ==''){
          this.valueProgressBar = this.valueProgressBar - 16
          this.progressBarValidator.desaTerpilih = false
         
        }


        if (this.progressBarValidator.alamatLengkap == false && this.valueProgressBar >= 0 && this.alamatData.alamatLengkap !=''){
          this.valueProgressBar = this.valueProgressBar + 6
          this.progressBarValidator.alamatLengkap = true
        
        }
        else if (this.progressBarValidator.alamatLengkap == true && this.valueProgressBar >= 0 && this.alamatData.alamatLengkap ==''){
          this.valueProgressBar = this.valueProgressBar - 6
          this.progressBarValidator.alamatLengkap = false
         
        }



      },
      deep: true
    },


    'informasiTambahan': {
      immediate: true,
      async handler() {
       
        
        if (this.progressBarValidator.clientGrup == false && this.valueProgressBar >= 0 && this.informasiTambahan.clientGrup !=''){
          this.valueProgressBar = this.valueProgressBar + 12
          this.progressBarValidator.clientGrup = true
         
        }
        else if (this.progressBarValidator.clientGrup == true && this.valueProgressBar >= 0 && this.informasiTambahan.clientGrup ==''){
          this.valueProgressBar = this.valueProgressBar - 12
          this.progressBarValidator.clientGrup = false
          
        }

      },
      deep: true
    },

    'identitasPelangganForm.email':{
      immediate:true,
       handler (coba){
        
        
           this.validEmail(coba)
         
            if (this.progressBarValidator.email == false && this.valueProgressBar >= 0 && this.identitasPelangganForm.email !='' && this.maxEmailMin ==0){
          this.valueProgressBar = this.valueProgressBar + 7
          this.progressBarValidator.email = true
          this.maxEmailMin = 1
          

        }
        
             if (this.progressBarValidator.email == true && this.valueProgressBar >= 0 && this.identitasPelangganForm.email == ""&&  this.maxEmailMin == 1){
          this.valueProgressBar = this.valueProgressBar - 7
          this.progressBarValidator.email = false
          this.maxEmailMin = 0
         
        }
          
     
        
      }
    },
    'identitasPelangganForm.fname':{
      immediate:true,
       handler (){
        if (this.identitasPelangganForm.fname != ''){
          this.identitasPelangganForm.fname = this.identitasPelangganForm.fname.toUpperCase();
        }
       
        
    }
  },

  
   
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
    },
   



    
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
   
    mounted() {
      this.choseNegara()
    },
    isChose(e) {

      this.listNegara = e.target.value

    },
    isChoseProvinsi() {
      
      let a = this.alamatData.ProvinsiTerpilih.id

      this.choseKota(a)
    },
    isChoseKota() {
      let a = this.alamatData.kotaTerpilih.id
      
      this.choseKecamatan(a)
    },
    isChoseKecamatan() {
      let a = this.alamatData.kecamatanTerpilih.id
      
      this.choseDesa(a)
      
    },
    async clientGrupGet() {

      await axios.get(process.env.VUE_APP_URL_FEATHERS+'client-grup').then(response => {
        this.listClientGrup = response.data;
       
        
      });

    },

    async choseNegara() {
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'list-negara').then(response => {
        this.alamatPelanggan.negara = response.data;
      });

    },
    async choseProvinsi() {
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'list-provinsi').then(response => {
        this.alamatPelanggan.provinsi = response.data;
       
      });

    },
    async choseKota(parent) {

      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'list-kota?parent=' + parent).then(response => {
        this.alamatPelanggan.kota = response.data;
        
      });
      
    },
    async choseKecamatan(parent) {
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'list-kecamatan?parent=' + parent).then(response => {
        this.alamatPelanggan.kecamatan = response.data;
        
      });

    },
    async choseDesa(parent) {
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'list-desa?parent=' + parent).then(response => {
        this.alamatPelanggan.desa = response.data;
        
      });

    },
    validationData(){
     
      this.validate()
      var a = this.validatePhoneNumber()
      if (a){
        this.confrimPendaftaran()
       
      }
      else{
        console.log("data kurang");
      }
      
      
      
    },
    validatePhoneNumber(){
     
      if(this.phoneNumberMethod(this.nonNormalInputNumber.nomorHandphone) &&  this.phoneNumberChecker){
       // console.log("validasi true");
        return true
      }
     
      
      return false
      
    },

    validate(){
     
      this.emailBlured = true;
      if (this.identitasPelangganForm.email != ''){
          if( this.validEmail(this.identitasPelangganForm.email)){
              this.valid = true;
            
          }
          else {
            this.valid = false;
          }
      }
    },

    validEmail(email) {
        var re = /(.+)@(.+){1,}\.(.+){1,}/;    
        return re.test(email.toLowerCase());
    },

    phoneNumberMethod(phoneNumber){
      
      if (phoneNumber!= null) {
        if (phoneNumber.length >=3){
                  if(_.startsWith(phoneNumber,'08') || _.startsWith(phoneNumber,'628')|| _.startsWith(phoneNumber,'62')){
                    if(_.startsWith(phoneNumber,'6208') ||_.startsWith(phoneNumber,'08') ){
                      this.nonNormalInputNumber.nomorHandphone = "628"
                      }
                      else if (phoneNumber.length >= 10){
                      
                        this.phoneNumberChecker = true
                        
                        this.identitasPelangganForm.nomorHandphone = this.nonNormalInputNumber.nomorHandphone
                       
                      }
                        else {
                         
                        this.phoneNumberChecker = false
                          }
                    
                  return true
                  }
                      else{   
                      return false
                    }
      
      }
      return true
    }

  },


  

 removeString(){
  this.alamatData.alamatLengkap = ""
 },


async confrimPendaftaran() {

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success ms-3 center',
    cancelButton: 'btn btn-danger'
  },
  buttonsStyling: false
});

await swalWithBootstrapButtons.fire({
  title: 'Apakah anda ingin melakukan Pendaftaran Pelanggan?',
  text: "Silahkan Pastikan Data yang anda masukan telah tepat",
  icon: 'warning',
  confirmButtonColor: "#34c38f",
  cancelButtonColor: "#f46a6a",
  showCancelButton: true,
  confirmButtonText: 'Daftarkan',
  cancelButtonText: 'Batalkan',
  reverseButtons: true
}).then(async(result) => {
  this.axioscheck = false
  
      if(result.isConfirmed){
       
        await this.postPendaftaran()
      }
      
      
  
            if (result.isConfirmed && this.axioscheck == true) {
              
              
              
              swalWithBootstrapButtons.fire(
                'Sukses!',
                'Terima Kasih telah melakukan Registrasi',
                'success'
              )
            
            } else if (result.dismiss === Swal.DismissReason.cancel) 
            {
              
              swalWithBootstrapButtons.fire(
                'Dibatalkan',
                'Periksa kembali Data Pelanggan',
                'error'
              )
              
            }
            else {
              swalWithBootstrapButtons.fire(
                'error!',
                'Terjadi Kesalahan Silahkan Coba Beberapa Saat Lagi',
                'info'
              )
            }
          })

},
async postPendaftaran(){

  
  let dataCus={
    idn:this.identitasPelangganForm.nomorKtpTandaPengenal,
    name: this.identitasPelangganForm.fname+' '+this.identitasPelangganForm.lname,
    email:this.identitasPelangganForm.email,
    address:this.alamatData.alamatLengkap,
    notes:this.informasiTambahan.catatanTambahan,
    status:this.informasiTambahan.status,
    clientGroup:this.informasiTambahan.clientGrup.text,
    phonenumber:this.identitasPelangganForm.nomorHandphone,
    phonenumberOptional:this.identitasPelangganForm.nomorHandphoneAlternatif,
    district:{
      country:this.alamatData.NegaraTerpilih.id,
      province:this.alamatData.ProvinsiTerpilih.id,
      city:this.alamatData.kotaTerpilih.id,
      subDistrict:this.alamatData.kecamatanTerpilih.id,
      urbanVillage:this.alamatData.desaTerpilih.id,
    }
  }


  
    await axios.post(process.env.VUE_APP_URL_FEATHERS+ 'create-new-customer',dataCus).then(async response =>{
      // console.log(response.data);
      if (response.data.status == true){
        this.axioscheck = true
        console.log("pendaftaraan benar");
      
              if(response.data != 0){
                let routeRef = '/customer/' + response.data.cus
                this.activityDataSetRegis.user = response.data.cusName
                this.activityDataSetRegis.refRoute = routeRef
                this.activityDataSetRegis.handleBy = this.$store.state.authfack.user.username
                console.log(this.activityDataSetRegis);

                await axios.post(process.env.VUE_APP_URL_FEATHERS+ 'recent-activity',this.activityDataSetRegis).then(async responseCus =>{
                  console.log("berhasil post activity",responseCus);
                }).catch((errorAct) => {
               console.log(errorAct);
                });
                
              this.$router.push('/customer/' + response.data.cus)
                  }
                else {
                  console.log("Kesalahan Server / Code");
                }
               
        
       }
      else {
        this.axioscheck = false
      }
    }).catch((error) => {
          console.log(error);
      })
  

  }
}
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <div class="row-6" align="center" >
      <div class ="col-lg-5">
        
      <div class="card">
        
          <div class="card-body">
            <h4 class="card-title mb-4" > Kode Area Pemasangan </h4>

            <form class="needs-validation" @submit.prevent="tooltipForm">
              <div class="row">
                <div class = "col-1"></div>
                <div class="col-md-5">
                  <div class="mb-3 position-relative">
                    <div class="form-floating">
                    
                   
                    <select name="clientGrup" id="clientGrup" v-model="informasiTambahan.clientGrup" class="form-select" 
                    style ="height: calc(3.2rem + 2px);min-height: calc(0.5rem + 2px);"
                      @click="clientGrupGet" :class="{
                        'is-invalid': submitform && $v.informasiTambahan.clientGrup.$error,
                        'border border-1 border border-success' : informasiTambahan.clientGrup != '' && !informasiTambahan.clientGrup.$error ,
                      }" >
                      <option id="clientGrup._id" v-for="client in listClientGrup"  
                        v-bind:value="{ id: client._id, text: client.groupCode }" :key="client.groupCode">
                        {{ client.groupCode }}
                      </option>
                    </select>
                    <label for="validationTooltip01">Kode Area*</label>
                    <div v-if="submitform && $v.informasiTambahan.clientGrup.$error" class="invalid-tooltip">
                      <span v-if="!$v.tooltipform.fname.required">Silahkan Pilih Client Grup Pelanggan</span>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
             </form>
          </div>
        </div>
      </div>
      <!-- end col -->
      <div class= "row-6 row-md-center">
      <div class="col-lg-5">
        <div class="card" style = "width: 101%;">
          <div class="card-body">
            <h5 class="card-title mb-4"> Identitas Pelanggan</h5>

            <form class="needs-validation" @submit.prevent="identitasForm">
              <div class="row">
                <div class = "col-1"></div>
                <div class="col-md-5">
                  <div class="mb-3 position-relative">
                  
                    <form class="form-floating">
                      <input id="validationTooltipUsername"
                        v-model="identitasPelangganForm.nomorKtpTandaPengenal"
                        name = "nomorKtpTandaPengenal"
                        type="text"
                        v-mask="'#################'"
                        class="form-control" placeholder="Nomor Handphone" 
                        style ="height: calc(3rem + 2px);min-height: calc(0.5rem + 2px);"
                        v-bind:class="{
                        'is-invalid': submitform && $v.identitasPelangganForm.nomorKtpTandaPengenal.$error,
                        'border border-3 border border-info' : inputStyle.focused6,
                       'border border-1 border border-success' : identitasPelangganForm.nomorKtpTandaPengenal != '',
                       }" 
                        @focus="inputStyle.focused6 = true" @blur="inputStyle.focused6 = false "/>
                        <div class="invalid-tooltip">Silahkan Periksa Nomor Tanda Pengenal Pelanggan</div>
                      <label for="nomorHandphone">Nomor Tanda Pengenal*</label>
                    
                    
                  </form>
                    

                   
                    <div v-if="submitform && $v.identitasPelangganForm.nomorKtpTandaPengenal.$error"
                      class="invalid-tooltip">
                      <span v-if="!$v.identitasPelangganForm.nomorKtpTandaPengenal.required">Silahakan Masukan Nomor Identitas Pelanggan</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-5">
                  
                  <div class="mb-3 position-relative">

                    <div class="form-floating size-md">

                      <input id="fname" v-model="identitasPelangganForm.fname" type="text"
                      
                      class="form-control" style ="height: calc(3rem + 2px);min-height: calc(0.5rem + 2px);"
                      placeholder="Nama" variant="solo-inverted" :class="{
                        'is-invalid': submitform && $v.identitasPelangganForm.fname.$error,
                        'border border-3 border border-info' : inputStyle.focused1,
                       'border border-1 border border-success' : identitasPelangganForm.fname != '',
                       
                      }" 

                      @focus="inputStyle.focused1 = true" @blur="inputStyle.focused1 = false" />
                      <label for="fname">Nama*</label>

                      <div v-if="submitform && $v.identitasPelangganForm.fname.$error" class="invalid-tooltip">
                      <span v-if="!$v.identitasPelangganForm.fname.required">Silahakan Masukan Nama  Pelanggan.</span>                                             
                    </div>
                    </div>

                     
                  </div>
                </div>
               
                
              </div>
              <div class="row">
                <div class = "col-1 "></div>
                <div class="col-md-5">
                  <div class="mb-3 position-relative">
                    <form class="form-floating">
                      <input id="validationTooltipUsername"
                        v-model="identitasPelangganForm.email"
                        name = "email"
                        type="email"
                        class="form-control" placeholder="Email" 
                        style ="height: calc(3rem + 2px);min-height: calc(0.5rem + 2px);"
                        v-bind:class="{
                          'is-invalid':
                             !validEmail(identitasPelangganForm.email) && emailBlured,
                             'border border-3 border border-info' : inputStyle.focused3,
                             'border border-1 border border-success' : identitasPelangganForm.email != '' && validEmail(identitasPelangganForm.email) && emailBlured,
                            }" 
                        @focus="inputStyle.focused3 = true" @blur="emailBlured = true ,inputStyle.focused3 = false "/>
                        <div class="invalid-tooltip">Silahkan Periksa inputan Email anda</div>
                      <label for="email">Email*</label>
                    
                    
                  </form>

                   
                   
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="mb-3 position-relative">
                    <form class="form-floating">
                      <input id="validationTooltipUsername"
                        v-model="nonNormalInputNumber.nomorHandphone"
                        name = "nomorHandphone"
                        type="text"
                        v-mask="'##############'"
                        class="form-control" placeholder="Nomor Handphone" 
                        style ="height: calc(3rem + 2px);min-height: calc(0.5rem + 2px);"
                        v-bind:class="{
                         
                           'is-invalid':  !phoneNumberMethod(nonNormalInputNumber.nomorHandphone),
                             'border border-3 border border-info' : inputStyle.focused5,
                             'border border-1 border border-success' : phoneNumberChecker ,
                            }" 
                        @focus="inputStyle.focused5 = true" @blur="emailBlured = true ,inputStyle.focused5 = false "/>
                        <div class="invalid-tooltip">Silahkan Periksa Nomor Handphone</div>
                      <label for="nomorHandphone">Nomor Handphone*</label>
                    
                    
                  </form>
                    
                    <div v-if="!phoneNumberMethod(nonNormalInputNumber.nomorHandphone)" class="invalid-tooltip">
                      <span v-if="!phoneNumberMethod(nonNormalInputNumber.nomorHandphone)">Format Nomor Salah</span>
                    </div>
                  </div>
                </div>
                <div class = "row">
                  
                <div class = "col-1 " style ="width: 8.7%;"></div>
                <div class = "col-md-5 ">
                <div class="mb-3 position-relative">
                  <form class="form-floating">
                      <input id="validationTooltipUsername"
                        v-model="identitasPelangganForm.nomorHandphoneAlternatif"
                        name = "nomorHandphoneAlternatif"
                        type="text"
                        v-mask="'#################'"
                        class="form-control" placeholder="Nomor Handphone Alternatif" 
                        style ="height: calc(3rem + 2px);min-height: calc(0.5rem + 2px); width: 103%;"
                        v-bind:class="{
                       
                        'border border-3 border border-info' : inputStyle.focused7,
                       'border border-1 border border-success' : identitasPelangganForm.nomorHandphoneAlternatif != '',
                       }" 
                        @focus="inputStyle.focused7 = true" @blur="inputStyle.focused7 = false "/>
                      <label for="nomorHandphone">Nomor Handphone Alternatif</label>
                    
                    
                  </form>
                  
                  
                  
                 </div>
                </div>
              </div>
              </div>

            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    
      <div class="col-lg-5">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Alamat pelanggan*</h4>

             <form class="needs-validation" @submit.prevent="tooltipForm">
              <div class="row">
                <div class = "col-1"></div>
                <div class="col-md-10">
                  <div class="mb-3 position-relative">
                    <div class="form-floating">
                   
                    <input id="validationTooltip04" v-model="alamatData.alamatLengkap" type="text" class="form-control"
                    style ="height: calc(3.2rem + 2px);min-height: calc(0.5rem + 2px);background-position-x: right -9.25rem center;"
                      placeholder=""  :class="{
                        'is-invalid': submitform && $v.alamatData.alamatLengkap.$error,
                        'border border-3 border border-info' : inputStyle.focused4,
                        'border border-1 border border-success' : alamatData.alamatLengkap != ' ' && !alamatData.alamatLengkap.$error ,
                      }"
                      
                      @focus="inputStyle.focused4 = true"
                      @blur="inputStyle.focused4 = false"
                      @click ="removeString"
                      />

                    <label for="validationTooltip04">Alamat Lengkap*</label>
                    <div v-if="submitform && $v.alamatData.alamatLengkap.$error" class="invalid-tooltip">
                      <span v-if="!$v.tooltipform.state.required">Silahkan Masukan Alamat Lengkap Pelanggan</span>
                    </div>
                    </div>
                  </div>
                </div>
                </div>
                <div class="row">
                  <div class = "col-1"></div>
                <div class="col-md-5">
                  <div class="mb-3 position-relative">
                   
                    <div class="form-floating">
                    <select name="listNegara" id="listNegara" v-model="alamatData.NegaraTerpilih" @click="choseNegara"  :disabled="alamatData.alamatLengkap == ' '"
                      class="form-select" style ="height: calc(3.2rem + 2px);min-height: calc(0.5rem + 2px);" @change="isChose" :class="{
                        'is-invalid': submitform && $v.alamatData.NegaraTerpilih.$error,
                        'border border-1 border border-success' : alamatData.NegaraTerpilih != '',
                       
                      }">

                      <option id="negara._id" v-for="negara in alamatPelanggan.negara" v-bind:value="{id:negara._id, text:negara.name }"
                        :key="negara._id">{{
                          negara.name }}
                      </option>

                    </select>
                    <label for="validationTooltip01">Negara*</label>
                  

  
                    <div v-if="submitform && $v.alamatData.NegaraTerpilih.$error" class="invalid-tooltip">
                      <span v-if="!$v.tooltipform.fname.required">Silahakan Pilih Negara Pelanggan</span>
                    </div>
                  </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="mb-3 position-relative">
                    <div class="form-floating">
                   
                      <select name="listkota" id="listkota" v-model="alamatData.ProvinsiTerpilih" class="form-select"
                        @change="isChoseProvinsi" style ="height: calc(3.2rem + 2px);min-height: calc(0.5rem + 2px);"
                        :class="{
                        'is-invalid': submitform && $v.alamatData.ProvinsiTerpilih.$error,
                        'border border-1 border border-success' : alamatData.ProvinsiTerpilih != '',
                      }" :disabled="alamatData.NegaraTerpilih === ''">

                        <option id="listkota._id" v-for="provinsi in alamatPelanggan.provinsi"
                          v-bind:value="{ id: provinsi._id, text: provinsi.name }" :key="provinsi.name">
                          {{ provinsi.name }}
                        </option>

                      </select>

                      <label for="validationTooltipUsername" aria-label="Floating label select example">Provinsi*</label>
                     
                      <div v-if="submitform && $v.alamatData.kotaTerpilih.$error" class="invalid-tooltip">
                        <span v-if="!$v.tooltipform.username.required">Silahakan Pilih Kota Pelanggan</span>
                    </div>
                  </div>
                  </div>
                </div>
              </div>

               <div class="row">
                <div class = "col-1"></div>
                <div class="col-md-5">
                  <div class="mb-3 position-relative">
                    <div class="form-floating">
                   
                      <select name="listkota" id="listkota" v-model="alamatData.kotaTerpilih" class="form-select"
                        @change="isChoseKota" style ="height: calc(3.2rem + 2px);min-height: calc(0.5rem + 2px);"
                        :class="{
                        'is-invalid': submitform && $v.alamatData.kotaTerpilih.$error,
                        'border border-1 border border-success' : alamatData.kotaTerpilih != '',
                      }" :disabled="alamatData.ProvinsiTerpilih === ''">

                        <option id="listkota._id" v-for="kota in alamatPelanggan.kota"
                          v-bind:value="{ id: kota._id, text: kota.name }" :key="kota.name">
                          {{ kota.name }}
                        </option>

                      </select>

                      <label for="validationTooltipUsername" aria-label="Floating label select example">Kota*</label>
                     
                      <div v-if="submitform && $v.alamatData.kotaTerpilih.$error" class="invalid-tooltip">
                        <span v-if="!$v.tooltipform.username.required">Silahakan Pilih Kota Pelanggan</span>
                    </div>
                  </div>
                  </div>
                </div>

                <div class="col-md-5">
                  <div class="mb-3 position-relative">
                    <div class="form-floating">
                   

                    <select name="listKecamatan" id="listKecamatan" v-model="alamatData.kecamatanTerpilih"
                      class="form-select" style ="height: calc(3.2rem + 2px);min-height: calc(0.5rem + 2px);" @change="isChoseKecamatan" :class="{
                        'is-invalid': submitform && $v.alamatData.kecamatanTerpilih.$error,
                        'border border-1 border border-success' : alamatData.kecamatanTerpilih != '',
                      }" :disabled="alamatData.kotaTerpilih === ''">

                      <option id="kecamatanS._id" v-for="kecamatan in alamatPelanggan.kecamatan"
                        v-bind:value="{ id: kecamatan._id, text: kecamatan.name }" :key="kecamatan.name">
                        {{ kecamatan.name }}
                      </option>

                    </select>
                    <label for="validationTooltip03">Kecamatan*</label>
                    
                    <div v-if="submitform && $v.alamatData.kecamatanTerpilih.$error" class="invalid-tooltip">
                      <span v-if="!$v.tooltipform.city.required">Silahakan Pilih Kecamatan Pelanggan</span>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
             

              <div class="row">
                <div class = "col-1"></div>
                <div class="col-md-5">
                  <div class="mb-3 position-relative">

                    <div class="form-floating">
                    
                    <select name="listDesa" id="listDesa" v-model="alamatData.desaTerpilih" class="form-select" style ="height: calc(3.2rem + 2px);min-height: calc(0.5rem + 2px);" :class="{
                        'is-invalid': submitform && $v.alamatData.desaTerpilih.$error,
                        'border border-1 border border-success' : alamatData.desaTerpilih != '',
                      }" :disabled="alamatData.kecamatanTerpilih === ''">

                      <option id="desa._id" v-for="desa in alamatPelanggan.desa"
                        v-bind:value="{ id: desa._id, text: desa.name }" :key="desa.name">
                        {{ desa.name }}
                      </option>

                    </select>
                    <label for="validationTooltip04">Desa*</label>
                 
                    <div v-if="submitform && $v.alamatData.desaTerpilih.$error" class="invalid-tooltip">
                      <span v-if="!$v.tooltipform.state.required">Silahakan Pilih Desa Pelanggan</span>
                    </div>
                  </div>
                  </div>
                </div>
                
                
              </div>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>


      <!-- end col -->
    </div>
    <div class="row-6" align="center">
      <div class="col-md-5">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4"> Informasi tambahan </h4>

            <form class="needs-validation" @submit.prevent="tooltipForm">
            
            
                <div class="col-md-10">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip03">Catatan</label>
                    <textarea id="validationTooltip03" v-model="informasiTambahan.catatanTambahan" type="text" class="form-control"
                      placeholder="Catatan tambahan" />
                   
                  </div>
                </div>
              
              <button class="btn btn-primary" type="submit" @click="validationData" :disabled= "valueProgressBar != 100">Submit form</button>
            </form>
          </div>
          
        </div>
        <b-progress :value="valueProgressBar" :max= 100 variant="success" show-progress animated class= "mb-4"></b-progress>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->

    <!-- end row -->
  </Layout>
</template>
